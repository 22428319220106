import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";

function Helmet({ sitename = "InstallatieKast.shop", title = null, description = "Binnenkort beschikbaar!" }) {
    return (
        <ReactHelmet>
            <title>
                { title === null ? sitename : (title + " - " + sitename) }
            </title>
            <meta name="description" content={ description } />
        </ReactHelmet>
    );
}

export default Helmet;
