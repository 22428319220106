import React from "react";
import Helmet from "../components/Helmet";

function Privacyverklaring() {
    return (
        <React.Fragment>
            <Helmet
                title="Privacyverklaring"
            />
            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Privacyverklaring</h1>
                </div>
            </div>
            <div className="container mb-5">
                <p>
                    Smith Solar B.V., gevestigd aan Nobelstraat 11 BU4 2693 BC &#39;s-Gravenzande, is verantwoordelijk
                    voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
                </p>

                <p>Contactgegevens:</p>

                <ul>
                    <li>Website: <a href="https://installatiekast.shop" target="_blank" rel="noopener noreferrer">https://installatiekast.shop</a></li>
                    <li>Adres: Nobelstraat 11 BU4 2693 BC &#39;s-Gravenzande</li>
                    <li>Telefoonnummer: +31 (0)174 267 988</li>
                </ul>

                <h2>Persoonsgegevens die wij verwerken</h2>
                <p>
                    Smith Solar B.V. verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt.
                </p>
                <p>
                    Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:
                </p>
                <ul>
                    <li>Voor- en achternaam</li>
                    <li>Adresgegevens</li>
                    <li>Telefoonnummer</li>
                    <li>E-mailadres</li>
                    <li>IP-adres</li>
                    <li>Overige persoonsgegevens die u actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch</li>
                    <li>Gegevens over uw activiteiten op onze website</li>
                </ul>

                <h2>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h2>
                <p>
                    Smith Solar B.V. verwerkt uw persoonsgegevens voor de volgende doelen:
                </p>
                <ul>
                    <li>Het afhandelen van uw betaling</li>
                    <li>U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
                    <li>U te informeren over wijzigingen van onze diensten en producten</li>
                    <li>Om goederen en diensten bij u af te leveren</li>
                    <li>Smith Solar B.V. verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben voor onze belastingaangifte. </li>
                </ul>

                <h2>Geautomatiseerde besluitvorming</h2>
                <p>
                    Smith Solar B.V. neemt niet op basis van geautomatiseerde verwerkingen besluiten over zaken die
                    (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen
                    door computerprogramma&#39;s of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van
                    Smith Solar B.V.) tussen zit.
                </p>

                <h2>Bewaartermijn</h2>
                <p>
                    Smith Solar B.V. bewaart uw persoonsgegevens zolang dat nodig is voor de doelen die we in deze
                    verklaring noemen of om te voldoen aan wettelijke (bewaar)verplichtingen.
                </p>

                <h2>Delen van persoonsgegevens met derden</h2>
                <p>
                    Smith Solar B.V. verkoopt uw gegevens niet aan derden en verstrekt deze uitsluitend indien dit nodig
                    is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke verplichting.
                    Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om
                    te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. B&S Logistiek B.V.
                    blijft verantwoordelijk voor deze verwerkingen.
                </p>

                <h2>Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
                <p>
                    Smith Solar B.V. gebruikt alleen technische en functionele cookies. En analytische cookies die geen
                    inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze
                    website wordt opgeslagen op uw computer, tablet of smartphone. De cookies die wij gebruiken zijn
                    noodzakelijk voor de technische werking van de website en uw gebruiksgemak. Ze zorgen ervoor dat de
                    website naar behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij
                    hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door uw internetbrowser zo in
                    te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is
                    opgeslagen via de instellingen van uw browser verwijderen.
                </p>

                <h2>Gegevens inzien, aanpassen of verwijderen </h2>
                <p>
                    U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast
                    heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te
                    maken tegen de verwerking van uw persoonsgegevens door Smith Solar B.V. en heeft u het recht op
                    gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de
                    persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u
                    genoemde organisatie, te sturen.
                </p>
                <p>
                    U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens
                    of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens
                    sturen naar <a href="mailto:info@smithtechniek.nl">info@smithtechniek.nl</a>.
                </p>
                <p>
                    Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw
                    identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine
                    readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer
                    (BSN) zwart. Dit ter bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier
                    weken, op uw verzoek.
                </p>
                <p>
                    Smith Solar B.V. wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen
                    bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link:{" "}
                    <a
                        href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        https://<wbr/>autoriteitpersoonsgegevens.nl/<wbr/>nl/contact-met-de-autoriteit-<wbr/>persoonsgegevens/tip-ons
                    </a>
                </p>

                <h2>Hoe wij persoonsgegevens beveiligen</h2>
                <p>
                    Smith Solar B.V. neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om
                    misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te
                    gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van
                    misbruik, neem dan contact op met onze klantenservice of via{" "}
                    <a href="mailto:info@smithtechniek.nl">info@smithtechniek.nl</a>.
                </p>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Privacyverklaring);
