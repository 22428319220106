import React from "react";
import {
    DefaultProductPage
} from "@shopcrate/shopcrate-framework";

import Helmet from "../components/Helmet";
import Page404 from "../pages/Page404";

function ProductLayout() {
    return (
        <DefaultProductPage
            error404Page={ <Page404/> }
            helmet={ (productContext) => (
                <Helmet title={ productContext.product ? productContext.product.name : null }/>
            )}
        />
    )
}

export default React.memo(ProductLayout);
