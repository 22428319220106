import React from "react";
import { createRoot } from "react-dom/client";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import moment from "moment";
import "moment/locale/nl";

import App from "./App";

import * as serviceWorker from "./serviceWorker";

Sentry.init({
    dsn: "https://1419801a650a4dc49c892357e69caae2@sentry1.zsnode.com/45",
    autoSessionTracking: true,
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    // We recommend adjusting this value in production, or using tracesSampler for finer control
    tracesSampleRate: 1.0,
});

moment.locale("nl");

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <App />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
