import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Col,
    Container,
    Jumbotron,
    Row
} from "react-bootstrap";
import {
    ProductCollectionBar,
    SHOPCRATE_API_URL
} from "@shopcrate/shopcrate-framework";

import Helmet from "../components/Helmet";

function CategoryList({ children, className }) {
    let classNames = ["list-group"];
    if(className) {
        classNames.push(className);
    }
    return (
        <div
            className={ classNames.join(" ") }
            style={{
                maxWidth: "300px",
                fontSize: "1.2rem",
                fontWeight: "600"
            }}
        >
            { children }
        </div>
    )
}

function CategoryListItem({ title, categoryId }) {
    const dev = SHOPCRATE_API_URL !== "api.shopcrate.nl";
    const selectedCategoryId = dev ? categoryId.dev : categoryId.prod;
    return (
        <Link to={ "/category/" + selectedCategoryId } className="list-group-item list-group-item-action">
            <div className="d-flex align-items-center">
                <span className="flex-grow-1">
                    { title }
                </span>
                <i className="fas fa-arrow-alt-right float-right text-primary"/>
            </div>
        </Link>
    )
}

function Home() {
    return (
        <React.Fragment>
            <Helmet/>
            <Jumbotron
                fluid
                className="jumbotron-hero mb-0"
                style={{
                    color: "white",
                    backgroundColor: "#282828",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    overflow: "hidden"
                }}
            >
                <div className="hero-mobile-fader" style={{
                    paddingTop: 0,
                    paddingBottom: 0
                }}>
                    <Container style={{
                        minHeight: "700px"
                    }}>
                        <Row className="h-100 align-items-center">
                            <Col md={ 8 }>
                                <h1 className="h1-home logo-font">
                                    installatiekast.shop
                                </h1>
                                <CategoryList className="mb-2">
                                    <CategoryListItem categoryId={{ dev: 9, prod: 16 }} title="Automaten"/>
                                    <CategoryListItem categoryId={{ dev: 8, prod: 15 }} title="Aardlekschakelaars"/>
                                    <CategoryListItem categoryId={{ dev: 7, prod: 14 }} title="Aardlekautomaten"/>
                                    <CategoryListItem categoryId={{ dev: 11, prod: 18 }} title="Hoofdschakelaars"/>
                                    <CategoryListItem categoryId={{ dev: 10, prod: 13 }} title="Wandcontactdozen"/>
                                    <CategoryListItem categoryId={{ dev: 12, prod: 19 }} title="Groepenkasten"/>
                                </CategoryList>
                                <CategoryList>
                                    <CategoryListItem categoryId={{ dev: 5, prod: 17 }} title="Alle producten"/>
                                </CategoryList>
                            </Col>
                            <Col md={ 4 } className="d-none d-md-block">
                                <h1 className="text-right text-primary" style={{ fontSize: "10rem" }}>
                                    <i className="fas fa-bolt"/>
                                </h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Jumbotron>
            <div className="container mt-5">
                <ProductCollectionBar collection={ "home-collection" }/>
            </div>
            <div className="container pt-5 pb-5">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <p className="card-text"><b>InstallatieKast.shop</b></p>
                                <p className="card-text">
                                    Nobelstraat 11 BU4
                                    <br/>
                                    2693 BC 's-Gravenzande
                                </p>
                                <p className="card-text">
                                    <a href="dial:+31174267988">0174 267 988</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <iframe
                            id="map" title="map" width="100%" height="400" className="card" allowFullScreen
                            src="https://google.com/maps/embed?pb=!1m18!1m12!1m3!1d2456.6665251264644!2d4.178621265787967!3d51.9947314797182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b27f2ed9b7ed%3A0x7f0ca83a5fe1812!2sSmith%20Solar%20B.V.!5e0!3m2!1snl!2snl!4v1646042743375!5m2!1snl!2snl"
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Home);
