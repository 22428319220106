import React from "react";
import {
    DefaultCategoryPage
} from "@shopcrate/shopcrate-framework";

import Helmet from "../components/Helmet";
import Page404 from "../pages/Page404";

function CategoryLayout() {
    return (
        <DefaultCategoryPage
            error404Page={ <Page404/> }
            helmet={ (categoryContext) => (
                <Helmet title={ categoryContext.category ? categoryContext.category.name : null }/>
            )}
        />
    )
}

export default React.memo(CategoryLayout);
