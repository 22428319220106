import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Col,
    Container,
    Jumbotron,
    Row
} from "react-bootstrap";

function Footer() {
    const firstYear = 2022;
    const currentYear = new Date().getFullYear();
    let yearString =  firstYear + " - " + currentYear;
    if(firstYear === currentYear) {
        yearString = String(currentYear);
    }

    return (
        <React.Fragment>
            <Jumbotron fluid className="mb-0 text-white" style={{ backgroundColor: "#184262" }}>
                <Container>
                    <Row>
                        <Col md="4" className="my-3">
                            <h3>Webshop</h3>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/privacyverklaring" className="nav-link text-white px-0">Privacyverklaring</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/algemene-voorwaarden" className="nav-link text-white px-0">Algemene voorwaarden</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md="4" className="my-3">
                            <h3>Contact</h3>
                            <ul className="mt-2 fa-ul" style={ {
                                paddingLeft: "24px",
                                marginLeft: 0
                            } }>
                                <li className="mt-3">
                                    <span className="fa-li text-white"><i className="fas fa-envelope"/></span>
                                    <a href="mailto:info@smithtechniek.nl" className="text-white">info@smithtechniek.nl</a>
                                </li>
                                <li className="mt-3">
                                    <span className="fa-li text-white"><i className="fas fa-phone"/></span>
                                    <a href="dial:+31174267988" className="text-white">0174 267 988</a>
                                </li>
                            </ul>
                        </Col>
                        <Col md="4" className="my-3">
                            <h3>Info</h3>
                            <ul className="mt-2 fa-ul" style={ {
                                paddingLeft: "24px",
                                marginLeft: "8px"
                            } }>
                                <li className="mt-3">
                                    <span className="fa-li text-white">IBAN</span>
                                    <span className="text-white ml-3">
                                        NL53 INGB 0007 7734 22
                                    </span>
                                </li>
                                <li className="mt-3">
                                    <span className="fa-li text-white">KVK</span>
                                    <span className="text-white ml-3">
                                        60218452
                                    </span>
                                </li>
                                <li className="mt-3">
                                    <span className="fa-li text-white">BTW</span>
                                    <span className="text-white ml-3">
                                        NL853814405B01
                                    </span>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Jumbotron fluid className="mb-0 py-4" style={{ backgroundColor: "#122f44" }}>
                <Container className="text-white">
                    <p className="my-2 text-center text-md-left text-white">
                        <span className="d-none d-sm-block float-right">A website by <a href="https://srproductions.nl" target="_blank" rel="noopener noreferrer">SR Productions</a>.</span>
                        Copyright &copy; { yearString } Smith Solar B.V.{" "}
                        <span className="d-none d-sm-inline-block">
                            All rights reserved.
                        </span>
                        <span className="d-block d-md-none mt-2">
                            A website by <a href="https://srproductions.nl" target="_blank" rel="noopener noreferrer">SR Productions</a>.
                        </span>
                    </p>
                </Container>
            </Jumbotron>
        </React.Fragment>
    )
}



export default React.memo(Footer);
