import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {
    useShopCrateRoutes
} from "@shopcrate/shopcrate-framework";

import ScrollToTop from "./hooks/ScrollToTop";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Page404 from "./pages/Page404";
import Privacyverklaring from "./pages/Privacyverklaring";
import AlgemeneVoorwaarden from "./pages/AlgemeneVoorwaarden";
import Layout from "./layout/Layout";
import ProductLayout from "./layout/ProductLayout";
import CategoryLayout from "./layout/CategoryLayout";

const layouts = {
    regular: Layout,
    checkout: Layout,
    account: Layout,
    product: ProductLayout,
    category: CategoryLayout
}

function ShopRouter() {
    const shopCrateRoutes = useShopCrateRoutes(layouts);
    return (
        <React.Fragment>
            <ScrollToTop/>

            <div className="site-content">
                <Navbar/>

                <Switch>
                    <Route path="/" exact component={Home}/>

                    { shopCrateRoutes }

                    <Route path="/privacyverklaring" exact component={Privacyverklaring}/>
                    <Route path="/algemene-voorwaarden" exact component={AlgemeneVoorwaarden}/>

                    <Route path={ ["/index", "/index.html", "/index.htm", "/index.php"] } exact>
                        <Redirect to="/"/>
                    </Route>
                    <Route path="/" compoent={Page404}/>
                </Switch>
            </div>

            <Footer/>
        </React.Fragment>
    )
}

export default ShopRouter;
