import React, {
    useContext,
    useState
} from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    Container,
    Nav,
    Navbar as RBNavbar
} from "react-bootstrap";
import {
    AuthenticationContext,
    CartContext,
    ShopContext
} from "@shopcrate/shopcrate-framework";

function NavbarLink({to, title, exact = false, hidden = false, onClick}) {
    const match = useRouteMatch({
        path: to,
        exact: exact
    });
    const className = "nav-item" + (match ? " active" : "") + (hidden ? " d-none" : "");
    return (
        <li className={ className } data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to={ to } className="nav-link" onClick={ onClick }>
                { title }
            </Link>
        </li>
    );
}

function Navbar() {
    const authentication = useContext(AuthenticationContext);
    const cart = useContext(CartContext);
    const shop = useContext(ShopContext);

    let [showCollapsed, setShowCollapsed] = useState(false);
    let collapse = () => {
        setShowCollapsed(false);
    }

    return (
        <React.Fragment>
            <RBNavbar bg="primary" expand="lg" className="fixed-top" expanded={ showCollapsed }>
                <Container>
                    <a className="navbar-brand logo-font" href="/">
                        installatiekast.shop
                    </a>
                    <RBNavbar.Toggle aria-controls="basic-navbar-nav" onClick={ () => setShowCollapsed(!showCollapsed) } />
                    <RBNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto"/>
                        <Nav>
                            <NavbarLink to="/" title="Home" exact onClick={ collapse }/>
                            { shop.shop && shop.shop.orderingEnabled && (
                                <NavbarLink to="/cart" title={
                                    <React.Fragment>
                                        <i className="fas fa-shopping-cart"/>
                                        <span className="badge badge-pill badge-light ml-2">
                                            { cart.cart ? cart.cart.length : 0 }
                                        </span>
                                    </React.Fragment>
                                } exact onClick={ collapse }/>
                            )}
                            { authentication.supported && (
                                <NavbarLink to="/account" title={ <i className="fas fa-user fa-fw"/> } onClick={ collapse }/>
                            )}
                        </Nav>
                    </RBNavbar.Collapse>
                </Container>
            </RBNavbar>

            <div style={{ height: "64px" }}/>
        </React.Fragment>
    );
}

export default React.memo(Navbar);
